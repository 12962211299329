import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SplitButton } from 'primeng/splitbutton';
import { Dropdown } from 'primeng/dropdown';
import { ObjectUtils } from 'primeng/utils';

// Unfortunatly I cannot override this this way because it's a Signal property...
/*Dropdown.prototype.filled = function() {
  // if (typeof this.modelValue() === 'string') return !!this.modelValue();
  // return this.label() !== 'p-emptylabel' && this.modelValue() !== undefined && this.modelValue() !== null;
  if (typeof this.modelValue() === 'string') return this.modelValue() != null;
  return this.label() !== 'p-emptylabel' && this.modelValue() != null;
};*/

Dropdown.prototype.hasSelectedOption = function() {
  // return this.modelValue() !== undefined;
  return this.modelValue() !== undefined || (this.options || []).some(x => x.value === undefined);
}

Dropdown.prototype.getOptionValue = function(option: any) {
  // return this.optionValue && this.optionValue !== null ? ObjectUtils.resolveFieldData(option, this.optionValue)
  // : !this.optionLabel && option && option.value !== undefined ? option.value : option;
  return this.optionValue != null && this.optionValue !== '' ? ObjectUtils.resolveFieldData(option, this.optionValue)
  : !this.optionLabel && option && option.hasOwnProperty('value') ? option.value : option;
}

SplitButton.prototype.onDefaultButtonClick = function(event: Event) {
  // original event handler code
  // this.onClick.emit(event);

  // custom updated event handler code
  if (this.onClick?.observers?.length) {
    this.onClick.emit(event);
  } else {
    this.onDropdownClick.emit(event);
    this.menu.toggle({currentTarget: this.containerViewChild.nativeElement, relativeAlign: this.appendTo == null});
  }
}

if (environment.googleApiKey) {
  const gmapsApiScriptEl = document.createElement('script') as HTMLScriptElement;
  gmapsApiScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}`;
  gmapsApiScriptEl.async = true;
  gmapsApiScriptEl.defer = true;
  document.head.appendChild(gmapsApiScriptEl);
}

if (environment.production) {
  // add Google Analytics script to <head>
  const gtagSetupScript = document.createElement('script');
  gtagSetupScript.innerHTML = `(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
ga('create', '${(window as any).gaKey}', 'auto');`;

  document.head.appendChild(gtagSetupScript);
}

// handle all <a> click events to custom handle workspace widget navigation
document.addEventListener('click', (ev: any) => {
  const origin = ev.target.closest('a');

  if (origin?.href?.indexOf('/widget-link') >= 0) {
    ev.preventDefault();

    if ((window as any).widgetShortcut) {
      const params = origin.href.split('?')[1].split('&').filter(x => x)
      .reduce((previousValue: any, currentValue: any) => {
        const keyValuePair = currentValue.split('=');
        previousValue[keyValuePair[0]] = keyValuePair[1];
        return previousValue;
      }, {});

      (window as any).widgetShortcut(params);
    }
    return true;
  }

  return false;
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
.catch(err => console.warn(err));
