import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { CalendarModule } from 'angular-calendar';
import { FilterStringEditorModule } from 'app/center-v2/shared/components/filter-string-editor/filter-string-editor.module';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { NgxLoadingModule } from 'ngx-loading';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonComponentModule } from '../button/button.component.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { GridCellEditorAutoComplete } from './grid-celleditor-auto-complete/grid-celleditor-auto-complete.component';
import { GridCellEditorDaySegments } from './grid-celleditor-day-segments/grid-celleditor-day-segments.component';
import { GridCellEditorFilterStringEditor } from './grid-celleditor-filter-string-editor/grid-celleditor-filter-string-editor.component';
import { GridCellEditorFormField } from './grid-celleditor-form-field/grid-celleditor-form-field.component';
import { GridCellEditorPlannerDayArticle } from './grid-celleditor-planner-day-article/grid-celleditor-planner-day-article.component';
import { GridCellRendererCalendarDragHandle } from './grid-cellrenderer-calendar-drag-handle/grid-cellrenderer-calendar-drag-handle.component';
import { GridCellRendererDetailBlockedOrder } from './grid-cellrenderer-detail-blocked-order/grid-cellrenderer-detail-blocked-order.component';
import { GridCellRendererDetailGrids } from './grid-cellrenderer-detail-grids/grid-cellrenderer-detail-grids.component';
import { GridCellRendererIcon } from './grid-cellrenderer-icon/grid-cellrenderer-icon.component';
import { GridCellRendererLevel } from './grid-cellrenderer-level/grid-cellrenderer-level.component';
import { GridCellRendererText } from './grid-cellrenderer-text/grid-cellrenderer-text.component';
import { GridCellRendererWorkScheduleDay } from './grid-cellrenderer-workscheduleday/grid-cellrenderer-workscheduleday.component';
import { GridStatusBarRowCountComponent } from './grid-statusbar-row-count/grid-statusbar-row-count.component';
import { GridStatusBarUpdateComponent } from './grid-statusbar-update/grid-statusbar-update.component';
import { GridToolPanelAdminViewDesignComponent } from './grid-toolpanel-admin-view-design/grid-toolpanel-admin-view-design.component';
import { GridTooltipBarcode } from './grid-tooltip-barcode/grid-tooltip-barcode.component';
import { GridTooltipQRcode } from './grid-tooltip-qrcode/grid-tooltip-qrcode.component';
import { GridComponent } from './grid.component';


@NgModule({
  declarations: [
    GridCellEditorAutoComplete,
    GridCellEditorDaySegments,
    GridCellEditorFilterStringEditor,
    GridCellEditorFormField,
    GridCellEditorPlannerDayArticle,
    GridCellRendererCalendarDragHandle,
    GridCellRendererIcon,
    GridCellRendererLevel,
    GridCellRendererDetailBlockedOrder,
    GridCellRendererDetailGrids,
    GridCellRendererText,
    GridCellRendererWorkScheduleDay,
    GridStatusBarRowCountComponent,
    GridStatusBarUpdateComponent,
    GridToolPanelAdminViewDesignComponent,
    GridTooltipBarcode,
    GridTooltipQRcode,
    GridComponent,
  ],
  exports: [
    GridComponent,
  ],
  imports: [
    AppSharedV2Module,
    AccordionModule,
    AgGridModule,
    AutoCompleteModule,
    ButtonComponentModule,
    CalendarModule,
    FilterStringEditorModule,
    FormFieldModule,
    OverlayPanelModule,
    NgxLoadingModule,
  ],
})
export class GridModule { }
