import { GuidUtils } from 'app/shared/utils';
import { ReportSolutionTypeRelation } from './report-solution-type-relation.model';
import { SolutionType, SolutionTypeCoreType } from './solution-type.model';
import { ReportSolutionTypeMember } from './report-solution-type-member.model';


export enum ReportSolutionTypeType {
  Data = 0,
  Trace = 1,
  Custom = 10,
}

export class ReportSolutionType extends SolutionType {

  get isDefault(): boolean {
    return this.baseSolutionTypeGuidId === this.solutionTypeGuidId;
  }
  set isDefault(value: boolean) { return; } // required otherwise the Object.assign on the constructor fails

  centerTypeName: string;
  workspaceGuidId: string;
  storageGuidId: string;

  members: ReportSolutionTypeMember[];

  reportSolutionTypeType: ReportSolutionTypeType;
  reportRelations: ReportSolutionTypeRelation[];

  constructor(item?: Partial<ReportSolutionType>) {
    super(item);

    this.reportRelations = (this.reportRelations || []).map((i: ReportSolutionTypeRelation) => {
      return new ReportSolutionTypeRelation(i);
    });
  }

  static new(
    workspaceGuidId: string,
    storageGuidId: string,
    centerTypeGuidId: string,
    reportSolutionTypeType: ReportSolutionTypeType,
    name: string,
    isDefault: boolean,
  ) {
    const newItem = new ReportSolutionType();
    newItem.workspaceGuidId = workspaceGuidId;
    newItem.storageGuidId = storageGuidId;
    newItem.coreType = SolutionTypeCoreType.Report;
    newItem.baseSolutionTypeGuidId = centerTypeGuidId;
    newItem.solutionTypeGuidId = isDefault ? centerTypeGuidId : GuidUtils.new(),
    newItem.reportSolutionTypeType = reportSolutionTypeType;
    newItem.name = name;
    newItem.centerTypeName = name;

    return newItem;
  }

}
