import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CenterType, CenterTypeMember, ReportSolutionType, SolutionType, SolutionTypeMember, TypeEnum, TypeTreeRelation } from 'app/shared/models';
import { ReportSolutionTypeType } from 'app/shared/models/solution-type';
import { ReportSolutionTypeRelation } from 'app/shared/models/solution-type/report-solution-type-relation.model';
import { SessionService } from 'app/shared/services/app';
import { GuidUtils, JsonUtils } from 'app/shared/utils';
import { TreeNode } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { BaseComponentV2 } from '../base/base-v2.component';
import { WebCenterType, WebCenterTypeMember, WebCenterTypeMemberWeb2Access, WebCenterTypeRelation, WebPointerStatus } from 'app/center-v2/shared/models';
import { BaseDialog } from 'app/shared/dialogs';
import { SelectCenterTypeDialog } from 'app/shared/dialogs/select-center-type/select-center-type.dialog';
import { WebArticleGroup } from 'app/center-v2/shared/models/web-center-types/web-article-group.model';
import { WebArticleGroupOnNewSubTypeCollectionObject } from 'app/center-v2/shared/models/web-center-types/web-article-group-on-new-sub-type-collection-object.model';



@Component({
  selector: 'lc-center-type-tree',
  templateUrl: './center-type-tree.component.html',
  styleUrls: ['./center-type-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CenterTypeTreeComponent extends BaseComponentV2 {

  readonly TYPE_CHECKBOX = 'checkbox';
  readonly typeTreeRelationGuidIdMissions = 'c8361798-7f2e-4212-879b-f5e2a553f039';
  readonly typeTreeRelationGuidIdSelfRelation = '81bf4740-bca3-41e2-b4d8-ab6b2e27c37c';
  static readonly typeTreeRelationGuidIdRelationMembers = '9a5ff293-5fa0-4285-8d1c-3325b597458d';

  @ViewChild(SelectCenterTypeDialog, { static: true }) selectCenterTypeDialog: BaseDialog;

  @Input() allowObjectSelection: boolean = true;
  @Input() centerTypes: WebCenterType[] | CenterType[];
  @Input() customMembers: any[];
  @Input() expandAll: boolean;
  @Input() groups: string[] = ['Members', 'RelationsWithMembers', 'Relations', 'SubTypes', 'Base'];
  @Input() baseGroupItems: string[];
  @Input() limitToXLevels: number = 3;
  @Input() membersKey: string = 'members';
  @Input() selectedTreeNodeGuidId: string;
  @Input() singleSelection: boolean = false;
  @Input() set solutionType(value: SolutionType) {
    if (this._solutionType?.guidId !== value?.guidId) {
      setTimeout(() => {
        this.refresh();
      }, 10);
    }
    this._solutionType = value;

  }
  get solutionType(): SolutionType {
    return this._solutionType;
  }
  private _solutionType: SolutionType;
  @Input() solutionTypes: SolutionType[];
  @Output() addNewSolutionType: EventEmitter<any>;
  @Output() selectedMembersChanged: EventEmitter<void>;

  treeNodes: TreeNode[];
  selectedTN: TreeNode;

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
    private translateService: TranslateService,
  ) {
    super(cdr, sessionService);

    this.addNewSolutionType = new EventEmitter();
    this.selectedMembersChanged = new EventEmitter();
  }

  refresh() {
    this.treeNodes = [];
    if (!this.centerTypes || !this.solutionTypes || !this.solutionType) return;

    this.generateNodeForType(this.solutionType.baseSolutionTypeGuidId, null, -1)
    .subscribe((node: TreeNode) => {
      if (node) {
        this.treeNodes.push(node);
        this.onNodeSelect(node);
      } else {
        console.warn('oops...couldn\'t find corresponding CenterType...');
      }

      this.cdr.markForCheck();
    });
  }

  onNodeExpand(treeNode: TreeNode) {
    this.onNodeSelect(treeNode);
  }

  onNodeSelect(treeNode: TreeNode) {
    if (!this.singleSelection) {
      this.selectedTN = treeNode;
      this.cdr.markForCheck();
    }

    if (!treeNode.data.ready && !treeNode.leaf && treeNode.data?.typeGuidId) {
      const currentTreeNodeLevel = treeNode.parent ? this.getLevel(treeNode) + 1 : 0;

      // fetch relations for selected node
      let index = treeNode.children.findIndex((tn: TreeNode) => {
        return tn.label === this.translateService.instant('Relations');
      });
      if (index >= 0) {
        this.generateChildrenForSelectedNode(treeNode.children[index], currentTreeNodeLevel);
      }

      // fetch subTypes for selected node
      index = treeNode.children.findIndex((tn: TreeNode) => {
        return tn.label === this.translateService.instant('SubTypes');
      });
      if (index >= 0) {
        this.generateChildrenForSelectedNode(treeNode.children[index], currentTreeNodeLevel);
      }

      // fetch base stuff for selected node
      index = treeNode.children.findIndex((tn: TreeNode) => {
        return tn.label === this.translateService.instant('Base');
      });
      if (index >= 0) {
        this.generateChildrenForSelectedNode(treeNode.children[index], currentTreeNodeLevel);
      }

      treeNode.data.ready = true;
    }
  }

  private generateChildrenForSelectedNode(childrenNode: TreeNode, currentTreeNodeLevel: number) {
    for (const tn of childrenNode.children) {
      this.generateNodeForType(tn.data.typeGuidId, tn, currentTreeNodeLevel)
      .subscribe((node: TreeNode) => {
        if (!node && tn.data.typeGuidId !== GuidUtils.emptyGuid()) {
          // remove the node if the user doesn't have access to it (or we couldn't get the type definition)
          const index = childrenNode.children.findIndex((n: TreeNode) => {
            return tn.data.typeGuidId === n.data.typeGuidId;
          });
          childrenNode.children.splice(index, 1);
          childrenNode.leaf = childrenNode.children.length === 0;
        }

        this.cdr.markForCheck();
      });
    }
  }

  private generateNodeForType(typeGuidId: string, treeNode: TreeNode, currentTreeNodeLevel: number): Observable<TreeNode> {
    const subject = new Subject<TreeNode>();

    this.getType(typeGuidId)
    .subscribe((ct: WebCenterType | CenterType) => {
      if (ct || typeGuidId === TypeEnum.Mission) {
        treeNode = treeNode || {
          label: ct.name,
          icon: (ct as CenterType).designTreeNodeIconUri,
          collapsedIcon: (ct as CenterType).designTreeNodeIconUri,
          expanded: true,
          data: { typeGuidId: typeGuidId }
        };
        Object.assign(treeNode.data, { centerType: ct });

        treeNode.children = [];
        if (this.groups.indexOf('Members') >= 0) {
          treeNode.children.push({ label: this.translateService.instant('Members'), leaf: true, children: [], data: {}, expanded: this.expandAll });
        }
        if (this.groups.indexOf('RelationsWithMembers') >= 0) {
          treeNode.children.push({ label: this.translateService.instant('Relations with Members'), leaf: true, children: [], data: {}, expanded: this.expandAll });
        }
        if (this.groups.indexOf('Relations') >= 0) {
          treeNode.children.push({ label: this.translateService.instant('Relations'), leaf: true, children: [], data: {}, expanded: this.expandAll });
        }
        if (this.groups.indexOf('SubTypes') >= 0) {
          treeNode.children.push({ label: this.translateService.instant('SubTypes'), leaf: true, children: [], data: {}, expanded: this.expandAll });
        }
        if (this.groups.indexOf('Base') >= 0) {
          treeNode.children.push({ label: this.translateService.instant('Base'), leaf: true, children: [], data: {}, expanded: this.expandAll });
        }
        if (this.customMembers) {
          treeNode.children.push({ label: this.translateService.instant('Custom'), leaf: true, children: [], data: {}, expanded: this.expandAll });
        }

        const parentIsList = treeNode.data.parentIsList || treeNode.data.relation?.collectionRelation;

        let index = treeNode.children.findIndex((tn: TreeNode) => {
          return tn.label === this.translateService.instant('Members');
        });
        if (index >= 0) {
          for (const member of ct?.members || []) {
            if (
              (!(member as CenterTypeMember).systemReadOnly && (member as WebCenterTypeMember).web2Access !== WebCenterTypeMemberWeb2Access.None) &&
              (((this.solutionType as ReportSolutionType).reportSolutionTypeType == null && !(member as CenterTypeMember).traceOnly && !(member as CenterTypeMember).isTrace) ||
              ((this.solutionType as ReportSolutionType).reportSolutionTypeType === ReportSolutionTypeType.Data && !(member as CenterTypeMember).traceOnly) ||
              ((this.solutionType as ReportSolutionType).reportSolutionTypeType === ReportSolutionTypeType.Trace && (member as CenterTypeMember).isTrace))
            ) {
              this.addChildItemToTreeNode(
                {
                  typeGuidId: ct.typeGuidId,
                  guidId: member.guidId,
                  parentIsList: parentIsList,
                  member: member,
                },
                treeNode.children[index],
                true
              );
            }
          }
        }

        index = treeNode.children.findIndex((tn: TreeNode) => {
          return tn.label === this.translateService.instant('Relations with Members');
        });
        if (index >= 0) {
          for (const relation of ct?.relations || []) {
            if (!(relation as TypeTreeRelation).systemOnly) {
              const existingRelation = treeNode.children[index].children.find((tn: TreeNode) => {
                return tn.data.childTypeGuidId === relation.childTypeGuidId &&
                  tn.data.parentTypeGuidId === relation.parentTypeGuidId;
              });
              if (
                !existingRelation &&
                relation.name &&
                relation.parentTypeGuidId === typeGuidId &&
                relation.relationMembers?.length
              ) {
                const newTN = this.addChildItemToTreeNode(
                  {
                    centerType: ct,
                    typeGuidId: relation.childTypeGuidId,
                    guidId: relation.typeTreeRelationGuidId,
                    isList: relation.collectionRelation,
                    parentIsList: parentIsList,
                    relation: relation,
                    isRelationPointer: true,
                  },
                  treeNode.children[index],
                  false,
                );
                newTN.children.push({ label: this.translateService.instant('Members'), leaf: true, children: [], data: {} });

                for (const relationMember of relation.relationMembers || []) {
                  if (
                    !(relationMember as CenterTypeMember).systemReadOnly &&
                    !(relationMember as CenterTypeMember).traceOnly &&
                    !(relationMember as CenterTypeMember).isTrace
                  ) {
                    const relationMemberTN = this.addChildItemToTreeNode(
                      {
                        typeGuidId: relation.childTypeGuidId,
                        guidId: relationMember.guidId,
                        isList: relation.collectionRelation,
                        parentIsList: parentIsList,
                        member: relationMember,
                      },
                      newTN.children[0],
                      true,
                    );
                    relationMemberTN.type = undefined;
                  }
                }
              }
            }
          }
        }

        index = treeNode.children.findIndex((tn: TreeNode) => {
          return tn.label === this.translateService.instant('Relations');
        });
        if (index >= 0) {
          if (ct.typeGuidId === WebArticleGroup.typeGuidId && !ct.relations.some(x => x.typeGuidId === WebArticleGroupOnNewSubTypeCollectionObject.typeGuidId)) {
            ct.relations.push(new WebCenterTypeRelation({
              childTypeGuidId: WebArticleGroupOnNewSubTypeCollectionObject.typeGuidId,
              collectionRelation: true,
              name: 'OnNewSubTypes',
              parentTypeGuidId: WebArticleGroup.typeGuidId,
              web2Access: 3,
              // web2Collection: true,
              web2Relation: true,
              typeTreeRelationGuidId: GuidUtils.new(),
              typeGuidId: WebArticleGroupOnNewSubTypeCollectionObject.typeGuidId,
            } as any) as any);
          }
          for (const relation of ct?.relations || []) {
            if ((relation as TypeTreeRelation).systemOnly) continue;

            const existingRelation = treeNode.children[index].children.find((tn: TreeNode) => {
              return tn.data.childTypeGuidId === relation.childTypeGuidId &&
                tn.data.parentTypeGuidId === relation.parentTypeGuidId;
            });
            if (
              !existingRelation &&
              relation.name &&
              relation.parentTypeGuidId === typeGuidId
            ) {
              this.addChildItemToTreeNode(
                {
                  typeGuidId: relation.childTypeGuidId,
                  guidId: relation.typeTreeRelationGuidId,
                  isList: relation.collectionRelation,
                  parentIsList: parentIsList,
                  relation: relation,
                  isRelationPointer: false,
                },
                treeNode.children[index],
                this.limitToXLevels && this.limitToXLevels <= currentTreeNodeLevel + 2,
              );
            }
          }
        }

        index = treeNode.children.findIndex((tn: TreeNode) => {
          return tn.label === this.translateService.instant('SubTypes');
        });
        if (index >= 0) {
          // old way of declaring subtypes (still need to be supported)
          const subTypeGuidIds = (ct as WebCenterType)?.subTypeGuidIds || (ct as CenterType)?.subTypesTypeGuidId || [];
          for (const subTypeGuidId of subTypeGuidIds) {
            const subType = (this.centerTypes || [] as any[]).find((x: WebCenterType | CenterType) => {
              return x.typeGuidId === subTypeGuidId;
            });
            if (subType) {
              this.addChildItemToTreeNode(
                {
                  typeGuidId: subTypeGuidId,
                  guidId: subTypeGuidId,
                  parentIsList: parentIsList,
                  subType: subType,
                },
                treeNode.children[index],
                this.limitToXLevels && this.limitToXLevels <= currentTreeNodeLevel + 2,
              );
            }
          }

          // new way of declaring subtypes
          const subTypes = (this.centerTypes || [] as any[])
          .filter((x: WebCenterType) => {
            return x.typeGuidId !== ct.typeGuidId &&
              (x.isRootUntyped || x.rootTypeGuidId === ct.typeGuidId || x.typeGuidId === (ct as WebCenterType).rootTypeGuidId);
          });
          for (const subType of subTypes || []) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: subType.guidId,
                guidId: subType.guidId,
                parentIsList: parentIsList,
                subType: subType,
              },
              treeNode.children[index],
              this.limitToXLevels && this.limitToXLevels <= currentTreeNodeLevel + 2,
            );
          }
          this.addChildItemToTreeNode(
            {
              typeGuidId: GuidUtils.emptyGuid(),
              guidId: 'addSubType',
              parentIsList: parentIsList,
              subType: { name: 'Other?' }
            },
            treeNode.children[index],
            true
          );
        }

        // base "section"
        index = treeNode.children.findIndex((tn: TreeNode) => {
          return tn.label === this.translateService.instant('Base');
        });
        if (index >= 0) {
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('SysId') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: GuidUtils.emptyGuid(),
                guidId: 'sysId',
                parentIsList: parentIsList,
                base: { name: 'SysId', $fieldType: 'text' }
              },
              treeNode.children[index],
              true
            );
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('CreatedDateTime') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: GuidUtils.emptyGuid(),
                guidId: 'createdDateTime',
                parentIsList: parentIsList,
                base: { name: 'CreatedDateTime', $fieldType: 'datetime' }
              },
              treeNode.children[index],
              true
            );
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('ChangedDateTime') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: GuidUtils.emptyGuid(),
                guidId: 'changedDateTime',
                parentIsList: parentIsList,
                base: { name: 'ChangedDateTime', $fieldType: 'datetime' }
              },
              treeNode.children[index],
              true
            );
          }
          if ((!this.baseGroupItems?.length && treeNode.data.typeGuidId !== TypeEnum.Site) || (this.baseGroupItems || []).indexOf('Site') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: TypeEnum.Site,
                guidId: TypeEnum.Site,
                parentIsList: parentIsList,
                site: { name: 'Site' }
              },
              treeNode.children[index],
              this.limitToXLevels && this.limitToXLevels <= currentTreeNodeLevel + 2,
            );
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('Missions') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: TypeEnum.Mission,
                guidId: TypeEnum.Mission,
                isList: true,
                parentIsList: false,
                relation: { name: 'Missions', typeTreeRelationGuidId: this.typeTreeRelationGuidIdMissions, web2Access: 1, web2Relation: {} }
              },
              treeNode.children[index],
              true
            );
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('Self Relation') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: this.solutionType.baseSolutionTypeGuidId,
                guidId: this.solutionType.baseSolutionTypeGuidId,
                isList: true,
                parentIsList: false,
                relation: { name: 'Self Relation', typeTreeRelationGuidId: this.typeTreeRelationGuidIdSelfRelation, web2Access: 1, web2Relation: {} }
              },
              treeNode.children[index],
              true
            );
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('Relation Members') >= 0) {
            if ((ct.relations || []).some(r => r.childTypeGuidId === this.solutionType.baseSolutionTypeGuidId && r.relationMembers?.length)) {
              this.addChildItemToTreeNode(
                {
                  typeGuidId: this.solutionType.baseSolutionTypeGuidId,
                  guidId: this.solutionType.baseSolutionTypeGuidId,
                  isList: true,
                  parentIsList: false,
                  relation: { name: 'Relation Members', typeTreeRelationGuidId: CenterTypeTreeComponent.typeTreeRelationGuidIdRelationMembers, }
                },
                treeNode.children[index],
                true
              );
            }
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('Traces') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: ct.typeGuidId,
                guidId: ct.typeGuidId,
                parentIsList: parentIsList,
                traces: { name: 'Traces' }
              },
              treeNode.children[index],
              true
            );
          }
          if (!this.baseGroupItems?.length || this.baseGroupItems.indexOf('Tick') >= 0) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: GuidUtils.emptyGuid(),
                guidId: GuidUtils.emptyGuid(),
                parentIsList: parentIsList,
                tick: { name: 'Tick' }
              },
              treeNode.children[index],
              true
            );
          }
        }

        // custom "section"
        index = treeNode.children.findIndex((tn: TreeNode) => {
          return tn.label === this.translateService.instant('Custom');
        });
        if (index >= 0) {
          for (const customMember of this.customMembers || []) {
            this.addChildItemToTreeNode(
              {
                typeGuidId: customMember.typeGuidId,
                guidId: GuidUtils.emptyGuid(),
                parentIsList: parentIsList,
                custom: { name: customMember.label, $fieldType: 'custom tab' },
              },
              treeNode.children[index],
              true
            );
          }
        }
      } else {
        treeNode = null;
        console.warn(`Hiding node as the corresponding CenterType couldn't be found: ${typeGuidId}`);
      }

      subject.next(treeNode);
      subject.complete();
    });

    return subject;
  }

  private getType(typeGuidId: string): Observable<WebCenterType | CenterType> {
    const subject = new Subject<WebCenterType | CenterType>();

    const type = (this.centerTypes || [] as any[]).find((t: WebCenterType | CenterType) => {
      return t.typeGuidId === typeGuidId;
    });
    if (type) {
      setTimeout(() => {
        subject.next(type);
        subject.complete();
      });
    } else {
      // Commented out because, if the type is not in the above list is because the user
      // doesn't have access to it...
      /* this.typeService.get(typeGuidId)
      .subscribe((t: Type) => {
        subject.next(t);
        subject.complete();
      });*/
      setTimeout(() => {
        subject.next(null);
        subject.complete();
      });
    }

    return subject;
  }

  private addChildItemToTreeNode(data: any, treeNode: TreeNode, isLeaf: boolean): TreeNode {
    const tn: TreeNode = {
      children: [],
      data: data,
      expanded: this.expandAll,
      label: data.member ? data.member.name :
             data.collection ? data.collection.collectionName :
             data.relation ? data.relation.name :
             data.subType ? data.subType.name :
             data.site ? data.site.name :
             data.tick ? data.tick.name :
             data.traces ? data.traces.name :
             data.base ? data.base.name :
             data.custom ? data.custom.name : '',
      leaf: isLeaf,
      type: this.TYPE_CHECKBOX,
    };

    treeNode.leaf = false;
    treeNode.children.push(tn);

    if (tn?.data?.guidId === this.selectedTreeNodeGuidId) {
      this.selectedTN = tn;
    }

    return tn;
  }

  isNodeChecked(treeNode: TreeNode) {
    return this.getMemberIndex(treeNode) >= 0;
  }

  onNodeCheckedChanged(treeNode: TreeNode, checked: boolean) {
    if (!((this.solutionType as ReportSolutionType).reportSolutionTypeType != null && treeNode.data.isList)) {
      if (treeNode.data.relation || treeNode.data.collection || treeNode.data.traces) {
        if (checked) {
          this.addTypeToSolutionType(treeNode);
        } else {
          this.removeTypeFromSolutionType(treeNode);
        }
      }

      if (checked) {
        if (this.singleSelection) {
          this.setSelectedMemberToSolutionType(treeNode);
        } else {
          this.addMemberToSolutionType(treeNode);
        }
      } else {
        this.removeMemberFromSolutionType(treeNode);
      }
      console.log(JSON.stringify(this.solutionType, null, 2));
    }

    this.selectedMembersChanged.emit();
  }

  private addTypeToSolutionType(treeNode: TreeNode) {
    if ((this.solutionType as ReportSolutionType).reportSolutionTypeType != null) {
      const existingST = this.solutionTypes
      .find((st: SolutionType) => {
        return st.baseSolutionTypeGuidId === treeNode.data.typeGuidId &&
          (!treeNode.data.traces || (st as ReportSolutionType).reportSolutionTypeType === ReportSolutionTypeType.Trace);
      });
      if (existingST) {
        const rstr = new ReportSolutionTypeRelation({
          targetGuidId: existingST.guidId,
          targetType: (existingST as ReportSolutionType).reportSolutionTypeType,
          $name: existingST.name,
        });
        (this.solutionType as ReportSolutionType).reportRelations.push(rstr);
      } else {
        this.addNewSolutionType.emit({
          centerTypeGuidId: treeNode.data.typeGuidId,
          reportSolutionTypeType: treeNode.data.traces ? ReportSolutionTypeType.Trace : ReportSolutionTypeType.Data,
          name: treeNode.label,
        });
      }
    } else {
      const index = this.solutionType.objectTypeGuidIds
      .findIndex((typeGuidId: string) => {
        return typeGuidId === treeNode.data.typeGuidId;
      });
      if (index < 0) {
        this.solutionType.objectTypeGuidIds.push(treeNode.data.typeGuidId);
      }
    }
  }

  private removeTypeFromSolutionType(treeNode: TreeNode) {
    const index = this.solutionType.objectTypeGuidIds.findIndex((typeGuidId: string) => {
      return typeGuidId === treeNode.data.typeGuidId;
    });
    if (index >= 0) {
      this.solutionType.objectTypeGuidIds.splice(index, 1);
    }
  }

  private getMemberIndex(treeNode: TreeNode) {
    const members = JsonUtils.deepFind(this.solutionType, this.membersKey, true, []);
    return (members || [])
    .findIndex((member: SolutionTypeMember) => {
      return member.centerTypeMemberGuidId === treeNode.data.guidId
      && member.path === SolutionTypeMember.getPath(treeNode)
      && (member.isRelationPointer == null || member.isRelationPointer === treeNode.data.isRelationPointer);
    });
  }

  private setSelectedMemberToSolutionType(treeNode: TreeNode) {
    const newMember = SolutionTypeMember.fromTreeNode(treeNode);
    this.populateSolutionTypeSubMembers(newMember, treeNode, this.getLevel(treeNode));
    this.solutionType[this.membersKey] = newMember;
  }

  private addMemberToSolutionType(treeNode: TreeNode) {
    const members = JsonUtils.deepFind(this.solutionType, this.membersKey, true, []);
    const existingIndex = this.getMemberIndex(treeNode);
    if (existingIndex >= 0) return;

    const member = SolutionTypeMember.fromTreeNode(treeNode);
    this.populateSolutionTypeSubMembers(member, treeNode, this.getLevel(treeNode));
    members.push(member);
  }

  private removeMemberFromSolutionType(treeNode: TreeNode) {
    const members = JsonUtils.deepFind(this.solutionType, this.membersKey, true, []);
    const index = this.getMemberIndex(treeNode);
    if (index >= 0) {
      members.splice(index, 1);
    }
  }

  private getLevel(treeNode: TreeNode): number {
    let currentNode: TreeNode = treeNode;
    let level = 0;
    // loop until we find the level 0 node
    while (currentNode?.parent?.parent?.parent) {
      currentNode = currentNode.parent.parent;
      level++;
    }
    return level;
  }

  private populateSolutionTypeSubMembers(
    member: SolutionTypeMember, treeNode: TreeNode, level: number,
  ) {
    if (treeNode && treeNode.data.guidId) {
      member.subLevels = Math.max(member.subLevels || 0, level);
      member.subMembers[level] = member.buildSubMemberNode(treeNode);

      this.populateSolutionTypeSubMembers(
        member,
        treeNode.parent ? treeNode.parent.parent : null,
        --level
      );
    }
  }

  hasSubMembers(treeNode: TreeNode) {
    let hasSubMembers = false;
    const members = JsonUtils.deepFind(this.solutionType, this.membersKey, true, []);
    if (!Array.isArray(members)) return hasSubMembers;

    if (treeNode.data.relation) {
      for (const member of members || []) {
        for (const subMemberKey of Object.keys(member.subMembers)) {
          hasSubMembers = hasSubMembers || member.subMembers[subMemberKey].relationGuidId === treeNode.data.relation.typeTreeRelationGuidId;
        }
      }
    } else if (treeNode.data.subType) {
      for (const member of members || []) {
        for (const subMemberKey of Object.keys(member.subMembers)) {
          hasSubMembers = hasSubMembers || member.subMembers[subMemberKey].fromTypeGuidId === treeNode.data.subType.typeGuidId;
        }
      }
    } else if (treeNode.data.site) {
      for (const member of members || []) {
        for (const subMemberKey of Object.keys(member.subMembers)) {
          hasSubMembers = hasSubMembers || member.subMembers[subMemberKey].fromTypeGuidId === treeNode.data.typeGuidId;
        }
      }
    }

    return hasSubMembers;
  }

  addSubType(node: TreeNode) {
    this.selectCenterTypeDialog.show({
      centerTypes: this.centerTypes,
      hideName: true,
    });
    this.selectCenterTypeDialog.onClose = (result: any) => {
      if (!result) return;

      const parentNode = node.parent;
      const currentTreeNodeLevel = parentNode.parent ? this.getLevel(parentNode.parent) : 0;
      const subType = (this.centerTypes as WebCenterType[] || []).find(x => x.typeGuidId === result.centerTypeGuidId);

      this.addChildItemToTreeNode(
        {
          typeGuidId: subType.guidId,
          guidId: subType.guidId,
          parentIsList: node.data?.parentIsList,
          subType: subType,
        },
        parentNode,
        this.limitToXLevels && this.limitToXLevels <= currentTreeNodeLevel + 2,
      );
      this.generateChildrenForSelectedNode(parentNode, currentTreeNodeLevel + 1);
    };
  }

}


